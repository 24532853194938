import React from 'react';
import { usePrivy } from '@privy-io/react-auth';
import logo from './assets/logo-tp.png';
import './App.css';
import MemeGenerator from './components/MemeGenerator';
import { Button } from './components/ui/button';

function App() {
  const { ready, authenticated, logout, user } = usePrivy();

  const handleLogout = async () => {
    if (user?.wallet && 'disconnect' in user.wallet) {
      try {
        await user.wallet.disconnect();
        console.log('Wallet disconnected successfully');
      } catch (err) {
        console.log('Wallet disconnection error:', err);
      }
    }
    await logout();
    console.log('Logged out from Privy');
  };

  if (!ready) {
    return (
      <div className="min-h-screen bg-[#FEFFEB] flex items-center justify-center">
        <div className="animate-pulse">Loading...</div>
      </div>
    );
  }

  console.log('Authentication status:', authenticated);
  console.log('User wallet:', user?.wallet?.address);

  return (
    <div className="min-h-screen bg-[#FEFFEB] p-8">
      <div className="flex justify-between items-center mb-8">
        <img src={logo} alt="logo" className="w-16 h-16" />
        {authenticated && (
          <div className="flex items-center gap-4">
            {user?.wallet?.address && (
              <div className="text-sm text-[#322E29] bg-[#C59E57]/10 px-3 py-1 rounded-full">
                {`${user.wallet.address.substring(0, 6)}...${user.wallet.address.substring(user.wallet.address.length - 4)}`}
              </div>
            )}
            <Button
              onClick={handleLogout}
              className="bg-[#322E29] hover:bg-[#423D36] text-[#FEFFEB] transition-colors"
            >
              Disconnect Wallet
            </Button>
          </div>
        )}
      </div>
      {/* <h3 className="text-2xl font-bold mb-4">Powered By Bnna</h3> */}
      <MemeGenerator />
    </div>
  );
}

export default App;
