import React, { useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Loader2 } from 'lucide-react';
import { usePrivy } from '@privy-io/react-auth';

// Add whitelist array of wallet addresses
const WHITELISTED_WALLETS = [
  '9oMoQZf7WHz7RjP9nmQ9y894ReNvSnphLMGBKLWwwZaC', // Example wallet address
  '0xb8252a18Ef53695734e9A573C729ae65a248Ff71', // Example wallet address
  // Add more wallet addresses as needed
];

const MemeGenerator = () => {
  const { user, login, ready, logout, authenticated } = usePrivy();
  const [prompt, setPrompt] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const checkWalletEligibility = async () => {
    console.log('Checking wallet eligibility...');
    console.log('User object:', user);
    console.log('Wallet address:', user?.wallet?.address);
    
    if (!user?.wallet?.address) {
      console.log('No wallet address found');
      setError('Please connect your wallet first');
      return false;
    }
    
    // Check if wallet is whitelisted (case-insensitive comparison)
    const userAddressLower = user.wallet.address.toLowerCase();
    console.log('User address (lowercase):', userAddressLower);
    console.log('Whitelisted wallets:', WHITELISTED_WALLETS);
    
    if (WHITELISTED_WALLETS.some(addr => addr.toLowerCase() === userAddressLower)) {
      console.log('Wallet is whitelisted, granting access');
      return true; // Whitelisted wallets are always eligible
    }
    
    console.log('Wallet not whitelisted, checking token balance...');
    
    // For non-whitelisted wallets, check BNNA token balance
    try {
      const tokenBalance = await checkBnnaTokenBalance(user.wallet.address);
      console.log('Token balance:', tokenBalance);
      
      // Define minimum required balance
      const minimumRequiredBalance = 1; // Require at least 1 BNNA token
      console.log('Minimum required balance:', minimumRequiredBalance);
      
      if (tokenBalance >= minimumRequiredBalance) {
        console.log('Sufficient token balance, granting access');
        return true;
      } else {
        console.log('Insufficient token balance');
        setError(`Insufficient BNNA tokens. You need at least ${minimumRequiredBalance} BNNA to use this feature.`);
        return false;
      }
    } catch (err) {
      console.error('Error checking token balance:', err);
      setError('Failed to verify token balance: ' + err.message);
      return false;
    }
  };

  const checkBnnaTokenBalance = async (walletAddress) => {
    console.log('Fetching BNNA balance for address:', walletAddress);
    try {
      const response = await fetch(`http://localhost:5001/api/bnna-balance/${walletAddress}`);
      console.log('API response status:', response.status);
      
      if (!response.ok) {
        throw new Error(`Server responded with status: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('API response data:', data);
      
      if (!data.success) {
        throw new Error(data.error || 'Failed to fetch BNNA balance');
      }
      
      console.log(`BNNA balance for ${walletAddress}: ${data.balance}`);
      return data.balance;
    } catch (error) {
      console.error('Error fetching BNNA balance:', error);
      throw error;
    }
  };

  const generateMeme = async () => {
    console.log('Generate meme button clicked');
    console.log('Authentication status:', authenticated);
    console.log('User object:', user);
    
    if (!authenticated || !user) {
      console.log('User not authenticated');
      setError('Please connect your wallet first');
      return;
    }
    
    console.log('Checking eligibility...');
    const isEligible = await checkWalletEligibility();
    console.log('Eligibility result:', isEligible);
    
    if (!isEligible) {
      console.log('User not eligible');
      return;
    }

    console.log('User eligible, generating meme...');
    setLoading(true);
    setError('');
    
    const options = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: 'Bearer key-xehKfsWxL4xzMZv14gOMbHCmI3jmA6AvAtAe5IyPCrV0JsTz9xFabVOBRQ1zaPzZ4I4BWEYCqJuTtIXkXZ84Xdel2129LCN'
      },
      body: JSON.stringify({ prompt })
    };

    try {
      const response = await fetch('https://api.getimg.ai/v1/flux-schnell/text-to-image', options);
      const data = await response.json();
      
      if (data.image) {
        setImageUrl(`data:image/png;base64,${data.image}`);
      } else {
        setError('Failed to generate image');
      }
    } catch (err) {
      setError('Error generating meme: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const downloadImage = () => {
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'meme.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleLogout = async () => {
    if (user?.wallet && 'disconnect' in user.wallet) {
      try {
        await user.wallet.disconnect();
      } catch (err) {
        console.log('Wallet disconnection error:', err);
      }
    }
    
    await logout();
    
    setPrompt('');
    setImageUrl('');
    setError('');
  };

  if (!ready) {
    return (
      <div className="min-h-screen w-full p-4 bg-[#FEFFEB] bg-opacity-90 flex items-center justify-center">
        <Loader2 className="h-8 w-8 animate-spin text-[#C59E57]" />
      </div>
    );
  }

  return (
    <div className="min-h-screen w-full p-4 bg-[#FEFFEB] bg-opacity-90 relative">
      {/* Background Image Layer */}
      <div 
        className="absolute inset-0 z-0 opacity-5"
        style={{
          backgroundImage: `url('https://github.com/apeoutmeme/resources/blob/25d45eafb420c43e12415d24d88abd4939b17f90/assets/bnna-token.webp?raw=true')`, // Add your background image to public folder
          backgroundSize: '200px',
          backgroundRepeat: 'repeat',
          pointerEvents: 'none'
        }}
      />
      
      {/* Main Card with glass effect */}
      <Card className="relative z-10 w-full max-w-2xl mx-auto bg-[#FEFFEB]/95 border-[#C59E57] border-2 backdrop-blur-sm shadow-xl">
        <CardHeader className="border-b border-[#C59E57]/20">
          <CardTitle className="text-[#322E29] text-2xl font-bold">
            AI Meme Generator
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-4 p-6">
          {!user ? (
            <Button 
              onClick={login} 
              className="w-full bg-[#C59E57] hover:bg-[#9B7940] text-[#FEFFEB] transition-colors"
            >
              Connect Wallet to Generate Memes
            </Button>
          ) : (
            <>
              <div className="flex space-x-4">
                <Input
                  placeholder="Enter your meme prompt..."
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  className="flex-1 border-[#C59E57] bg-[#FEFFEB] placeholder:text-[#423D36]/50 focus:ring-[#C59E57] focus:border-[#C59E57]"
                />
                <Button 
                  onClick={generateMeme}
                  disabled={loading || !prompt.trim()}
                  className="bg-[#C59E57] hover:bg-[#9B7940] text-[#FEFFEB] disabled:bg-[#423D36]/20 transition-colors"
                >
                  {loading ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Generating...
                    </>
                  ) : (
                    'Generate'
                  )}
                </Button>
              </div>

              {error && (
                <div className="text-red-500 text-sm p-3 bg-red-50 rounded-lg border border-red-200">
                  {error}
                </div>
              )}

              {imageUrl && (
                <div className="space-y-4">
                  <img 
                    src={imageUrl} 
                    alt="Generated meme"
                    className="w-full rounded-lg shadow-lg border-2 border-[#C59E57]"
                  />
                  <Button 
                    onClick={downloadImage}
                    className="w-full bg-[#322E29] hover:bg-[#423D36] text-[#FEFFEB] transition-colors"
                  >
                    Download Meme
                  </Button>
                </div>
              )}

              <Button
                onClick={handleLogout}
                variant="ghost"
                className="text-[#423D36] hover:text-[#322E29] hover:bg-[#C59E57]/10"
              >
                Disconnect
              </Button>
            </>
          )}
          
          <div className="mt-8 pt-6 border-t border-[#C59E57]/20 text-center">
            <p className="text-[#423D36] text-sm mb-2">
              This app requires $BNNA tokens to generate memes.
            </p>
            <a 
              href="https://dexscreener.com/solana/g3rhsoewmmvembdkbec6sfwu91rbmkdyeztwdnmawjxb"
              target="_blank" 
              rel="noopener noreferrer"
              className="inline-flex items-center text-[#C59E57] hover:text-[#9B7940] font-medium transition-colors"
            >
              Buy $BNNA tokens 
              <svg className="w-4 h-4 ml-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </a>
          </div>
        </CardContent>
      </Card>
      
      {/* Footer */}
      <footer className="mt-12 pb-6 text-center relative z-10">
        <div className="max-w-2xl mx-auto">
          <h3 className="text-xl font-bold text-[#322E29] mb-4">Join Our Community</h3>
          
          <div className="flex justify-center space-x-6 mb-6">
            {/* Telegram */}
            <a 
              href="https://t.me/apeoutmeme" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-[#C59E57] hover:text-[#9B7940] transition-colors"
              aria-label="Join our Telegram community"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"/>
              </svg>
            </a>
            
            {/* Twitter/X */}
            <a 
              href="https://twitter.com/apeoutmeme" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-[#C59E57] hover:text-[#9B7940] transition-colors"
              aria-label="Follow us on Twitter"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
              </svg>
            </a>
            
            {/* Discord */}
            <a 
              href="https://discord.com/invite/aWxyuHFS" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-[#C59E57] hover:text-[#9B7940] transition-colors"
              aria-label="Join our Discord server"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
                <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z"/>
              </svg>
            </a>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <a 
              href="https://dexscreener.com/solana/g3rhsoewmmvembdkbec6sfwu91rbmkdyeztwdnmawjxb" 
              target="_blank" 
              rel="noopener noreferrer"
              className="bg-[#C59E57] hover:bg-[#9B7940] text-[#FEFFEB] py-2 px-4 rounded-lg transition-colors flex items-center justify-center"
            >
              <span className="mr-2">🍌</span>
              Buy $BNNA Tokens
            </a>
            
            <a 
              href="http://apeout.fun" 
              target="_blank" 
              rel="noopener noreferrer"
              className="bg-[#322E29] hover:bg-[#423D36] text-[#FEFFEB] py-2 px-4 rounded-lg transition-colors flex items-center justify-center"
            >
              <span className="mr-2">👕</span>
              Shop Meme Merch
            </a>
          </div>
          
          <p className="mt-6 text-sm text-[#423D36]">
            © 2023 BNNA Token. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default MemeGenerator;